/* @tailwind base;
@tailwind components;
@tailwind utilities;
 */
// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

.ant-drawer {
  z-index: 1400; /* Use a value that suits your overlay stacking context */
}

.ant-select-dropdown {
  z-index: 1500; /* กำหนด z-index ตามต้องการ */
}


.ant-message {
  z-index: 1400; /* Use a value that suits your overlay stacking context */
}

.ant-notification {
  z-index: 1400; /* Use a value that suits your overlay stacking context */
}
// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}
.modal{
  font-family: 'Sarabun', sans-serif;
  font-size: 16px;
}

.tabs {
  font-family: 'Sarabun', sans-serif;
  font-size: 16px;
}
.descriptions {
  font-family: 'Sarabun', sans-serif;
  font-size: 24px;
}
.title-descriptions {
  font-family: 'Sarabun', sans-serif;
  font-size: 18px;
}
table {
  font-family: 'Sarabun', sans-serif;
  font-size: 16px;
}

/* สไตล์ปกติ */
h2 {
  font-size: 16px;
}

/* เมื่อหน้าจอมีความกว้างไม่เกิน 1200px */
@media (max-width: 1200px) {
  h2 {
      font-size: 16px;
  }
}

/* เมื่อหน้าจอมีความกว้างไม่เกิน 768px */
@media (max-width: 768px) {
  h2 {
      font-size: 16px;
  }
}

/* เมื่อหน้าจอมีความกว้างไม่เกิน 480px */
@media (max-width: 480px) {
  h2 {
      font-size: 16px;
  }
}
.text-descriptions *{
  font-family: 'Sarabun', sans-serif;
}

.text-size *{
  font-size: 16px;

}
/* In your CSS file */
.cell-top-align {
  vertical-align: top;
}
.custom-tag {
  margin-right: 8px; /* Adjust as needed */
}
/* 
table, td, th {  
  border: 1px solid #ddd;
  text-align: center;
}

table {
  border-collapse: collapse;
  width: 100%;
} */

th, td {
  padding: 15px;
}
@media print {
  body {
    width: 210mm;
    height: 297mm;
    margin: 0;
    padding: 0;
    /* Adjust font-sizes, margins, etc., as necessary */
  }

  /* Hide everything in the body by default */
  body * {
    visibility: hidden;
  }

  /* Only display the print section */
  #print-section, #print-section * {
    visibility: visible;
  }

  /* Position the print section at the top of the page */
  #print-section {
    position: absolute;
    left: 0;
    top: 0;
    width: 210mm;
    /* Adjust padding and margin as necessary */
  }
}


.custom-message {
  margin-top: '90vh'; /* ปรับค่า marginTop ตามที่คุณต้องการ */
}


.ant-table-tbody tr:hover {
  cursor: pointer; /* แสดงเป็นรูปมือเมื่อ hover ที่แถว */
}


.left-aligned-descriptions .ant-descriptions-item-content {
  text-align: left;
}


.DatePickerClassName {
  width: 100% !important;
  height: 40px !important;
  box-sizing: border-box !important;
}

/* styles.css */

.descriptions-custom .ant-descriptions-item-label {
  font-size: 16px; /* ขนาดตัวอักษรสำหรับ label */

}

.descriptions-custom .ant-descriptions-item-content {
  font-size: 16px; /* ขนาดตัวอักษรสำหรับ content */
}
